import React from 'react';
import Hero from '../Hero/Hero';
import Welcome from '../Welcome/Welcome';
import Services from '../Services/Services';
import HowWeWork from '../HowWeWork/HowWeWork';
import Projects from '../Projects/Projects';
import ContactUs from '../ContactUs/ContactUs';

function Home() {
  return <>
    <Hero />
    <Welcome/>
    <Services/>
    <HowWeWork/>
    <Projects/>
    <ContactUs/>
  </>
}

export default Home;