import React from 'react';
import { Fade } from 'react-awesome-reveal';
function Card({ img, title = false, disc }) {
  return <>
    <Fade>
      <div className='p-2 bg-[#232331] h-[300px] rounded-3xl shadow-[0_0px_30px_5px_rgba(58,59,74,0.24)] text-center flex flex-col hover:bg-slate-600 duration-[0.3s] justify-center'>
        <div>
          <img src={img} className='mx-auto' alt="logo" />
          {title ? <h3 className='font-bold text-[18px] sm:text-[24px] text-[#f4ab4f]'>{title}</h3> : null}
        </div>
        <div className='grow-1'>
          <p className='text-white font-medium text-[20px]'>{disc}</p>
        </div>
      </div>
    </Fade>

  </>
}

export default Card;