import React, { useState } from 'react';
import MainTitle from '../MainTitle/MainTitle';
import Mob from '../Mob/Mob';
import Desktop from '../Desktop/Desktop';

const Projects = () => {
  const [activeTab, setActiveTab] = useState('mobApp');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <section className='py-5'>
      <MainTitle
        spanFirst={true}
        span={'Alqudiry Solutions'}
        title={'PROJECTS'}
        desc={"Our digital agency's expertise spans visually stunning projects reflecting innovation and strategic thinking."}
      />
      <nav className='text-center'>
        <ul className='inline-block justify-center rounded-full px-3 py-2 bg-[#3A3B4A]'>
          <li className='mx-1 inline'>
            <span
              className={`text-white text-[20px] md:text-[28px] font-bold cursor-pointer px-2 py-1 ${activeTab === 'mobApp' ? 'bg-[#F4AB4F] rounded-full' : ''}`}
              onClick={() => handleTabClick('mobApp')}
            >
              Mob App
            </span>
          </li>
          <li className='mx-1 inline'>
            <span
              className={`text-white text-[20px] md:text-[28px] font-bold cursor-pointer px-2 py-1 ${activeTab === 'desktop' ? 'bg-[#F4AB4F] rounded-full' : ''}`}
              onClick={() => handleTabClick('desktop')}
            >
              Desktop
            </span>
          </li>
        </ul>
      </nav>
      <div className="container pt-8">
        {activeTab === 'mobApp' && (
          <Mob />
        )}
        {activeTab === 'desktop' && (
          <Desktop />
        )}
      </div>
    </section>
  );
};

export default Projects;
