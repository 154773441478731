import React from 'react';
import MainTitle from '../MainTitle/MainTitle';
import CoustomButton from '../CoustomButton/CoustomButton';
import { Fade } from 'react-awesome-reveal';

function ContactUs() {
  return <>
    <section className='py-10'>
      <MainTitle spanFirst={true} span={'Still have questions ?'} desc={'Leave a request and we will contact you .'} />
      <div className="container">
        <div className="flex items-center justify-center flex-wrap">
          <div className='w-full lg:w-1/2'>
            <form>
              <div className="flex flex-wrap">
                <div className='w-full md:w-1/2 my-2 px-1'>
                  <Fade duration={1200}>
                    <input type="text" placeholder='Name*' className='text-white border-0 outline-none rounded-xl w-full px-4 py-5 placeholder:text-white bg-[#3A3B4A]' />
                  </Fade>
                </div>
                <div className='w-full md:w-1/2 my-2 px-1'>
                  <Fade duration={1200}>
                    <input type="number" placeholder='Phone Number*' className='text-white border-0 outline-none rounded-xl w-full px-4 py-5 placeholder:text-white bg-[#3A3B4A]' />
                  </Fade>
                </div>
                <div className='w-full my-2 px-1'>
                  <Fade direction='up' duration={1200}>
                    <input type="email" placeholder='Email*' className='text-white border-0 outline-none rounded-xl w-full px-4 py-5 placeholder:text-white bg-[#3A3B4A]' />
                  </Fade>
                </div>
                <div className='w-full my-2 px-1'>
                  <Fade direction='up' duration={1200}>
                    <input type="text" placeholder='Subject*' className='text-white border-0 outline-none rounded-xl w-full px-4 py-5 placeholder:text-white bg-[#3A3B4A]' />
                  </Fade>
                </div>
                <div className='w-full my-2 px-1'>
                  <Fade direction='up' duration={1200}>
                    <textarea rows={10} placeholder='Message*' className='text-white border-0 outline-none rounded-xl w-full px-4 py-5 placeholder:text-white bg-[#3A3B4A]' />
                  </Fade>
                </div>
              </div>
              <CoustomButton text={'Submit'} />
            </form>
          </div>
        </div>
      </div>
    </section>
  </>
}

export default ContactUs;