import React from 'react';
import MainTitle from '../MainTitle/MainTitle';
import logo from '../../assets/Images/AL QUDIRY Solutions.png'
import { Fade, Slide } from 'react-awesome-reveal';

function HowWeWork() {
  return <>
    <section className='py-10'>
      <MainTitle title={'How we work'} />
      <div className="container">
        <div className="flex flex-wrap py-2 items-center">
          <div className='md:w-1/2 my-4 px-4 w-full'>
            <Slide direction='left' duration={1200}>
              <img src={logo} className='mx-auto' alt="logo" />
            </Slide>
          </div>
          <div className='md:w-1/2 my-4 px-4 w-full'>
            <div className="flex flex-wrap">
              <div className='md:w-1/2 my-4 px-4 w-full'>
                <Fade direction='up'>
                  <div>
                    <h4 className='font-bold text-[24px] text-white mb-3'>01 Audience</h4>
                    <p className='font-medium text-white text-[14px]'>Having these the marketplace to your business.</p>
                    <div className='w-[10px] h-[8px] rounded-full bg-blue-500 my-4'></div>
                  </div>
                </Fade>
              </div>
              <div className='md:w-1/2 my-4 px-4 w-full'>
                <Fade direction='up' delay={400}>
                  <div>
                    <h4 className='font-bold text-[24px] text-white mb-3'>02 Plan & Sketch</h4>
                    <p className='font-medium text-white text-[14px]'>Delicate and long-lasting with vitamins and nutrition.</p>
                    <div className='w-[10px] h-[8px] rounded-full bg-blue-500 my-4'></div>
                  </div>
                </Fade>
              </div>
              <div className='md:w-1/2 my-4 px-4 w-full'>
                <Fade direction='up' delay={800}>
                  <div>
                    <h4 className='font-bold text-[24px] text-white mb-3'>03 Customize</h4>
                    <p className='font-medium text-white text-[14px]'>Creating brand identities for the digital experiences.</p>
                    <div className='w-[10px] h-[8px] rounded-full bg-blue-500 my-4'></div>
                  </div>
                </Fade>
              </div>
              <div className='md:w-1/2 my-4 px-4 w-full'>
                <Fade direction='up' delay={1200}>
                  <div>
                    <h4 className='font-bold text-[24px] text-white mb-3'>04 User Testing</h4>
                    <p className='font-medium text-white text-[14px]'>We look forward to engaging beyond the conventional.</p>
                    <div className='w-[10px] h-[8px] rounded-full bg-blue-500 my-4'></div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
}

export default HowWeWork;