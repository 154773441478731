import React from 'react';
import bgLogo from '../../assets/Images/AL QUDIRY Solutions.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Parallax } from 'swiper/modules';
import CoustomButton from '../CoustomButton/CoustomButton';
function Hero() {
  return <>
    <section>
      <div className="container">
        <dir className=" p-0 my-0">
          <Swiper
            className="mySwiper"
            speed={1000}
            parallax={true}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
          >
            <div
              slot="container-start"
              className="parallax-bg"
              data-swiper-parallax="-23%"
            ></div>
            <SwiperSlide>
              <div className='relative min-h-[100vh] flex items-center justify-center z-[1]'>
                <img src={bgLogo} alt="Background logo" className='absolute top-1/2 opacity-50 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[-1]' />
                <h1 className='text-center text-[40px] xm:text-[45px] sm:text-[70px] md:text-[100px] lg:text-[128px] font-bold text-[#BC8FBF] tracking-[7px] md:tracking-[15px] lg:tracking-[20px]' data-swiper-parallax="-300">PREMIUM</h1>
                <div className='absolute bottom-0 mb-11'>
                  <p className='text-[#BC8FBF] text-center font-bold text-[24px]' data-swiper-parallax="-200">PREMIUM</p>
                  <p className='text-[#BC8FBF] text-center font-medium text-[24px]' data-swiper-parallax="-100">Exceptional Build Quality</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='relative min-h-[100vh] flex items-center justify-center z-[1]'>
                <img src={bgLogo} alt="Background logo" className='absolute top-1/2 opacity-50 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[-1]' />
                <h1 className='text-center text-[40px] xm:text-[45px] sm:text-[70px] md:text-[100px] lg:text-[128px] font-bold text-[#FEB563] tracking-[7px] md:tracking-[15px] lg:tracking-[20px]' data-swiper-parallax="-300">DIFFERENT</h1>
                <div className='absolute bottom-0 mb-11'>
                  <p className='text-[#FEB563] text-center font-bold text-[24px]' data-swiper-parallax="-200">DIFFERENT</p>
                  <p className='text-[#FEB563] text-center font-medium text-[24px]' data-swiper-parallax="-100">Stand out from the Crowd</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='relative min-h-[100vh] flex items-center justify-center z-[1]'>
                <img src={bgLogo} alt="Background logo" className='absolute top-1/2 opacity-50 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[-1]' />
                <h1 className='text-center text-[40px] xm:text-[45px] sm:text-[70px] md:text-[100px] lg:text-[128px] font-bold text-[#F587A6] tracking-[7px] md:tracking-[15px] lg:tracking-[20px]' data-swiper-parallax="-300">STYLISH</h1>
                <div className='absolute bottom-0 mb-11'>
                  <p className='text-[#F587A6] text-center font-bold text-[24px]' data-swiper-parallax="-200">STYLISH</p>
                  <p className='text-[#F587A6] text-center font-medium text-[24px]' data-swiper-parallax="-100">Solutions look Cool and Fashionable</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <CoustomButton text={"GET STARTED"} />
        </dir>
      </div>
    </section>
  </>
}
export default Hero;