import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { FaArrowRight } from 'react-icons/fa';

function CoustomButton({ text }) {
  return <>
    <Fade direction='up'>
      <div className='text-center my-6 flex justify-center divider before:grow-0 before:w-[200px] after:grow-0 after:w-[200px]'>
        <button className='bg-[#3A3B4A] group duration-[0.2s] hover:bg-[#F4AB4F] rounded-full flex items-center text-white p-2'>
          <span className='font-bold uppercase me-2'>{text}</span>
          <div className="flex items-center bg-[#232331] group-hover:bg-[#fff] justify-center h-8 w-8 rounded-[50%]">
            <FaArrowRight className='-rotate-45 duration-[0.2s] group-hover:rotate-0 group-hover:text-[#F4AB4F]' />
          </div>
        </button>
      </div>
    </Fade>
  </>
}

export default CoustomButton;