import logo from './logo.svg';
import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Layout from './Components/Layout/Layout';
import Home from './Components/Home/Home';

function App() {
  const routes = createBrowserRouter([
    { path:'', element:<Layout/> , children:[
      {index:true, element:<Home/>}
    ] }
  ])
  return <>
    <RouterProvider router={routes}>

    </RouterProvider>
  </>
}

export default App;
