import React from 'react';
import { Fade, Slide } from 'react-awesome-reveal';

function MainTitle({ br = null, title, desc=null, span=null, spanFirst = false }) {
  return <>
    <Slide direction='up'>
      <div className='text-center pb-6'>
        {spanFirst ? br ? <h3 className='text-white text-[25px] xm:text-[35px] sm:text-[46px] md:text-[56px] font-bold'> {br} <br /><span className='text-[#f4ab50]'>{span}</span> {title}</h3> : <h3 className='text-white text-[25px] xm:text-[35px] sm:text-[46px] md:text-[56px] font-bold'><span className='text-[#f4ab50]'>{span}</span> {title}</h3> :
          br ? <h3 className='text-white text-[25px] xm:text-[35px] sm:text-[46px] md:text-[56px] font-bold'> {br} <br />{title} <span className='text-[#f4ab50]'>{span}</span></h3> : <h3 className='text-white text-[25px] xm:text-[35px] sm:text-[46px] md:text-[56px] font-bold'> {title} <span className='text-[#f4ab50]'>{span}</span></h3>
        }
        <p className='text-white text-[16px] lg:w-[600px] mx-auto sm:[text-18px] md:text-[24px] font-medium'>{desc}</p>
      </div>
    </Slide>

  </>
}

export default MainTitle;