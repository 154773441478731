import React from 'react';
import MainTitle from '../MainTitle/MainTitle';
import CoustomButton from '../CoustomButton/CoustomButton';
import g from '../../assets/Images/Vector.png'
import e from '../../assets/Images/Vector (1).png'
import n from '../../assets/Images/Vector (2).png'
import i from '../../assets/Images/Vector (3).png'
import x from '../../assets/Images/Group.png'
import Card from '../Card/Card';
function Services() {
  const cards = [
    { img: g, title: 'S E O', desc: 'We help brands stand out through elegant visual design.' },
    { img: e, title: 'Web Development', desc: "We craft digital masterpieces that bring your brand's vision to life." },
    { img: n, title: 'Mobile App', desc: 'We empower brands through innovative mobile app solutions.' },
    { img: i, title: 'Software Development', desc: 'We make digital solutions for all, crafting innovations at a time.' },
    { img: x, title: 'UX UI Design', desc: 'We specialize in creating stunning, user-friendly digital experiences.' },
  ]
  return <>
    <section className='py-10'>
      <MainTitle spanFirst={true} span={"Alqudiry Solutions"} title={'SERVICES'} desc={'your gateway to cutting-edge technology solutions.'} />
      <CoustomButton text={'GET NOW'} />
      <div className="container">
        <div className="flex flex-wrap py-2 justify-center">
          {cards.map((card, i) => <div key={i} className='w-full lg:w-1/3 sm:w-1/2 px-5 my-5'>
            <Card img={card.img} disc={card.desc} title={card.title} />
          </div>
          )}
        </div>
      </div>
    </section>
  </>
}

export default Services;