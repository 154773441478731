import React, { useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import CoustomButton from '../CoustomButton/CoustomButton';
import iphone from '../../assets/Images/iPhone X.png';
import logo from '../../assets/Images/Logosd.png';

function Mob() {
  const [activeIcon, setActiveIcon] = useState(0);

  const projectContents = [
    {
      title: 'Project Name 1',
      description: 'Description for Project 1. Lorem ipsum dolor sit amet consectetur. Nisl arcu in ipsum augue vitae vel. Vitae consequat elit fames laoreet orci pharetra bibendum.',
    },
    {
      title: 'Project Name 2',
      description: 'Description for Project 2. Lorem ipsum dolor sit amet consectetur. Nisl arcu in ipsum augue vitae vel. Vitae consequat elit fames laoreet orci pharetra bibendum.',
    },
    {
      title: 'Project Name 3',
      description: 'Description for Project 3. Lorem ipsum dolor sit amet consectetur. Nisl arcu in ipsum augue vitae vel. Vitae consequat elit fames laoreet orci pharetra bibendum.',
    },
  ];

  return (
    <div className='flex flex-wrap items-center'>
      <div className="w-full hidden md:block md:w-1/3 px-4 my-4">
        <Fade duration={1500}>
          <img src={iphone} alt="phone" />
        </Fade>
      </div>
      <div className="w-full md:w-2/3 px-4 my-4">
        <div className='text-center flex flex-col h-full'>
          <div>
            <div className="project info">
              <Fade>
                <div className="project-text">
                  <h3 className='text-white font-bold text-[text-20px] md:text-[25px] lg:text-[28px]'>{projectContents[activeIcon].title}</h3>
                  <p className='text-white font-medium text-[text-14px] md:text-[18px] lg:text-[20px]'>{projectContents[activeIcon].description}</p>
                </div>
              </Fade>
              <CoustomButton text={'EXPLORE MORE'} />
            </div>
          </div>
          <div className='flex items-center mt-8 flex-wrap'>
            {projectContents.map((_, index) => (
              <div key={index} className="w-1/3 px-2">
                <Fade direction='up' delay={index * 400}>
                  <div 
                    className={`bg-[#3A3B4A] p-5 rounded-2xl ${activeIcon === index ? 'border border-[#F4AB4F]' : ''}`} 
                    onClick={() => setActiveIcon(index)}
                  >
                    <img src={logo} className='mx-auto' alt="icon" />
                  </div>
                </Fade>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mob;
