import React from 'react';
import MainTitle from '../MainTitle/MainTitle';
import CoustomButton from '../CoustomButton/CoustomButton';
import ai from '../../assets/Images/AI.png'
import community from '../../assets/Images/community.png'
import customer from "../../assets/Images/customer's journey.png"
import Card from '../Card/Card';
function Welcome() {
  const cards = [
    { img: ai, disc: "at Alqudiry Solutions we integrate artificial intelligence with everything creative ." },
    { img: customer, disc: "Make your customer's journey end with a successful purchase. We excel at this." },
    { img: community, disc: "streamline operations and create a connected creative community ." },
  ]
  return <>
    <section className='py-10'>
      <div className="container">
        <MainTitle br={"WELCOME"} title={'to the world of'} span={'Alqudiry Solutions'} desc={'Alqudiry Solutions is here to assist you in advancing your business towards a more technologically productive direction.'} />
        <CoustomButton text={"KNOW MORE"} />
        <div className="flex flex-wrap py-2 justify-center">
          {cards.map((card,i)=> <div key={i} className='w-full lg:w-1/3 sm:w-1/2 px-4 my-4'>
              <Card img={card.img} disc={card.disc}/>
          </div>
          )}
        </div>
      </div>
    </section>
  </>
}

export default Welcome;