import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { Outlet } from 'react-router-dom';
import { ReactLenis, useLenis } from 'lenis/react'

function Layout() {
  return <>
    <ReactLenis root options={{duration:1.3}}> 
      <Navbar />
      <Outlet />
      <Footer />
    </ReactLenis>
  </>
}

export default Layout;