import React from 'react';
import logo from '../../assets/Images/AL QUDIRY Solutions.png'
import genix from '../../assets/Images/AL QUDIRY Solutions2.png'
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';

function Footer() {
  return <>
    <footer className='bg-[#3A3B4A] py-1'>
      <div className="container">
        <div className="flex flex-wrap items-center">
          <div className="w-full px-4 my-4 lg:w-1/2">
            <div className="flex flex-wrap items-center">
              <div className="w-full px-4 my-4">
                <img src={genix} alt="logo" className='mb-4 w-[200px]' />
                <p className='text-white leading-5'>Lorem ipsum dolor sit amet consectetur. Enim at quam vitae facilisi urna morbi faucibus integer mus. Consequat quis faucibus enim lectus ligula ut risus diam lectus.</p>
                <div className='flex mt-5'>
                  <FaFacebookF className='text-white text-[25px] duration-[0.2s] me-3 hover:text-[#f4ab50] cursor-pointer' />
                  <FaTwitter className='text-white text-[25px] duration-[0.2s] mx-3 hover:text-[#f4ab50] cursor-pointer' />
                  <FaLinkedinIn className='text-white text-[25px] duration-[0.2s] mx-3 hover:text-[#f4ab50] cursor-pointer' />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full px-4 my-4 lg:w-1/2">
            <div className="flex flex-wrap">
              <div className='px-2 my-2 w-1/2 sm:w-1/3'>
                <h4 className='font-bold text-[28px] text-[#F4AB4F]'>Product</h4>
                <ul className='mt-4 list-none'>
                  <li className='my-4'>
                    <span className='text-white'>Features</span>
                  </li>
                  <li className='my-4'>
                    <span className='text-white'>Pricing</span>
                  </li>
                  <li className='my-4'>
                    <span className='text-white'>Reviews</span>
                  </li>
                </ul>
              </div>
              <div className='px-2 my-2 w-1/2 sm:w-1/3'>
                <h4 className='font-bold text-[28px] text-[#F4AB4F]'>Company</h4>
                <ul className='mt-4 list-none'>
                  <li className='my-4'>
                    <span className='text-white'>About</span>
                  </li>
                  <li className='my-4'>
                    <span className='text-white'>Contact us</span>
                  </li>
                  <li className='my-4'>
                    <span className='text-white'>Careers</span>
                  </li>
                  <li className='my-4'>
                    <span className='text-white'>Culture</span>
                  </li>
                  <li className='my-4'>
                    <span className='text-white'>Blog</span>
                  </li>
                </ul>
              </div>
              <div className='px-2 my-2 w-1/2 sm:w-1/3'>
                <h4 className='font-bold text-[28px] text-[#F4AB4F]'>Support</h4>
                <ul className='mt-4 list-none'>
                  <li className='my-4'>
                    <span className='text-white'>Getting started</span>
                  </li>
                  <li className='my-4'>
                    <span className='text-white'>Help center</span>
                  </li>
                  <li className='my-4'>
                    <span className='text-white'>Server status</span>
                  </li>
                  <li className='my-4'>
                    <span className='text-white'>Chat support</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </>
}

export default Footer;