import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/Images/AL QUDIRY Solutions.png';
import splitString from '../../utils/splitString';

const getStyle = (isHovered, index) => ({
  transform: `translateY(${isHovered ? '-100%' : '0%'})`,
  transitionDelay: `${index * 0.02}s`
});

function Navbar() {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const links = [
    { link: 'HOME', path: '/' },
    { link: 'ABOUT', path: '/' },
    { link: 'SERVICES', path: '/' },
    { link: 'PORTFOLIO', path: '/' },
    { link: 'CONTACT', path: '/' },
  ];

  return (
    <header className="bg-transparent backdrop-blur-md fixed z-[9999] left-0 right-0">
      <div className="container">
        <nav className="flex items-center py-2 justify-between">
          <div className="logo">
            <Link to={'/'}>
              <img src={logo} alt="Logo" className="w-[80px] px-4" />
            </Link>
          </div>
          <div className="links">
            <ul className="items-center hidden lg:flex">
              {links.map((link, i) => (
                <li className="mx-3" key={i}>
                  <Link
                    className="no-underline text-[#d69951] font-bold text-[18px]"
                    onMouseEnter={() => setHoveredIndex(i)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    to={link.path}
                  >
                    <span className="relative flex overflow-hidden">
                      {splitString(link.link).map((char, j) => (
                        <div key={j} className="relative">
                          <span
                            style={getStyle(hoveredIndex === i, j)}
                            className="inline-block duration-[0.5s] whitespace-pre"
                          >
                            {char}
                          </span>
                          <span
                            style={getStyle(hoveredIndex === i, j)}
                            className="inline-block duration-[0.5s] whitespace-pre absolute top-[100%] w-full left-0"
                          >
                            {char}
                          </span>
                        </div>
                      ))}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Navbar;
